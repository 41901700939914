import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';

import { parseImages, getEmMarkup } from 'src/helpers/parseMarkdown';
import { getAltText } from 'src/helpers/getAltText';
import svgDataURL from 'src/helpers/svgDataURL';

import {
  Assets,
  Hero,
  Resource,
  Contributors,
  Publication,
  PagesBackground,
  Stats,
  Content,
  Perks,
  PerksHeading,
  Quotes,
} from './Magazine.types';

export const parsePagesBackground = (markdown: string): PagesBackground => {
  return { images: parseImages(markdown) };
};

export const parseHero = (markdown: string): Hero => {
  const tokens = marked.lexer(markdown);
  const hero = {
    title: null,
    subtitle: null,
    images: [],
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        hero.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          hero.images = parseImages(markdown);
        } else if (token.tokens[0].type === 'text') {
          hero.subtitle = getEmMarkup(token.raw);
        }
        break;
      }
    }
  }

  return hero;
};

export const parseContributorsList = (markdown: string): Contributors['list'] => {
  const tokens = marked.lexer(markdown);
  const contributors = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        contributors.push({
          title: token.text,
          overline: null,
          subtitle: null,
          image: null,
        });
        break;
      }
      case 'list': {
        contributors[contributors.length - 1].overline = token.items[0].text;
        contributors[contributors.length - 1].subtitle = token.items[1].text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          contributors[contributors.length - 1].image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        }
        break;
      }
    }
  }

  return contributors;
};

export const parsePublication = (markdown: string): Publication => {
  const tokens = marked.lexer(markdown);
  const publication = {
    title: null,
    subtitle: null,
    list: [],
    image: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        publication.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          publication.image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else if (token.tokens[0].type === 'text' || token.tokens[0].type === 'em') {
          publication.subtitle = getEmMarkup(token.raw);
        }
        break;
      }
      case 'list': {
        publication.list = token.items.map((item) => item.text);
        break;
      }
    }
  }

  return publication;
};

export const parseStats = (markdown: string): Stats => {
  const tokens = marked.lexer(markdown);
  const stats = {
    title: null,
    paragraph: null,
    list: [],
    image: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        stats.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          stats.image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else if (token.tokens[0].type === 'text') {
          stats.paragraph = getEmMarkup(token.raw);
        }
        break;
      }
      case 'list': {
        stats.list = token.items.map((item) => getEmMarkup(item.text));
        break;
      }
    }
  }

  return stats;
};

export const parseContent = (markdown: string): Content => {
  const tokens = marked.lexer(markdown);
  const content = {
    title: null,
    subtitle: null,
    images: [],
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        content.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          content.images = parseImages(markdown);
        } else if (token.tokens[0].type === 'text') {
          content.subtitle = getEmMarkup(token.raw);
        }
        break;
      }
    }
  }

  return content;
};

export const parsePerksHeading = (markdown: string): PerksHeading => {
  const tokens = marked.lexer(markdown);
  let heading = null;

  for (const token of tokens) {
    if (token.type === 'heading') {
      heading = token.text;
    }
  }

  return heading;
};

export const parsePerks = (markdown: string): Perks => {
  const tokens = marked.lexer(markdown);
  const perks = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        perks.push({
          title: token.text,
          subtitle: null,
          svg: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.text.startsWith('<svg')) {
          perks[perks.length - 1].svg = svgDataURL(token.text);
        }
        perks[perks.length - 1].subtitle = token.text;
        break;
      }
    }
  }

  return perks;
};

export const parseQuotes = (markdown: string): Quotes => {
  const tokens = marked.lexer(markdown);
  const quotes = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        quotes.push({
          quote: token.text,
          member: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'text') {
          quotes[quotes.length - 1].member = token.text;
        }
        break;
      }
    }
  }

  return quotes;
};

const useMagazineAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      cta: parseHero(t(Resource.Cta)),
      content: parseContent(t(Resource.Content)),
      hero: parseHero(t(Resource.Hero)),
      contributors: {
        title: t(Resource.ContributorsTitle),
        list: parseContributorsList(t(Resource.ContributorsList)),
      },
      pagesShots: parsePagesBackground(t(Resource.PagesShots)),
      pagesLandscape: parsePagesBackground(t(Resource.PagesLandscape)),
      perks: parsePerks(t(Resource.Perks)),
      perksHeading: parsePerksHeading(t(Resource.PerksHeading)),
      publication: parsePublication(t(Resource.Publication)),
      quotes: parseQuotes(t(Resource.Quotes)),
      stats: parseStats(t(Resource.Stats)),
    };
  }, [ready]);
};

export { useMagazineAssets };
