import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Split } from 'src/constants/Split';
import { useAutoLogin } from 'src/hooks/useAutoLogin';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { useTracking } from 'src/components/TrackingContext/useTracking';
import {
  MagazineHero,
  MagazineContributors,
  MagazinePublication,
  MagazineStats,
  MagazineQuotes,
  MagazineContent,
  MagazinePerks,
  MagazineCta,
} from 'src/components/Magazine';
import { useMagazineAssets } from 'src/components/Magazine/useMagazineAssets';
import { useResponsiveImage } from 'src/hooks/useResponsiveImage';
import FadeIn from 'src/components/FadeIn';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import { MainFooter } from 'src/features/footer';

const Magazine: React.FC<PageProps> = () => {
  usePreventAgentAccess();
  useAutoLogin();
  const [experiments, setExperiments] = useState([]);
  const { t } = useTranslation();
  const { isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();
  const assets = useMagazineAssets();
  const { pagesShots, pagesLandscape } = assets;

  useEffect(() => {
    if (freeTrialIsReady) {
      const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
      setExperiments([freeTrialExperiment]);
    }
  }, [freeTrialIsReady]);

  useTracking('magazine_view', experiments);

  const shotsUrl = useResponsiveImage({
    mobile: pagesShots.images[0].url,
    tablet: pagesShots.images[1].url,
    desktop: pagesShots.images[2].url,
  });

  const landscapeUrl = useResponsiveImage({
    mobile: pagesLandscape.images[0].url,
    tablet: pagesLandscape.images[1].url,
    desktop: pagesLandscape.images[2].url,
  });

  return (
    <>
      <Helmet>
        <title>{t('head.title.magazine')}</title>
        <meta name="description" content={t('head.meta-description.magazine')} />
        <meta name="robots" content="all" />
        <body className="page__magazine page-container-padding" />
      </Helmet>
      <MagazineHero />
      <MagazineContributors />
      <MagazinePublication />
      <section>
        <FadeIn duration={0.4}>
          <img
            src={shotsUrl}
            alt={pagesShots.images[0].alt}
            className="full-width-image"
            data-cy="magazine-pages-shots-image"
          />
        </FadeIn>
      </section>
      <MagazineStats />
      <section>
        <FadeIn duration={0.4}>
          <img
            src={landscapeUrl}
            alt={pagesLandscape.images[0].alt}
            className="full-width-image"
            data-cy="magazine-pages-landscape-image"
          />
        </FadeIn>
      </section>
      <MagazineQuotes />
      <MagazineContent />
      <MagazinePerks />
      <MagazineCta />
      <footer className="container">
        <SiteDisclaimer />
      </footer>

      <MainFooter />
    </>
  );
};

export default Magazine;
