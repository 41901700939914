import React from 'react';

import { useMagazineAssets } from '../useMagazineAssets';
import { useResponsiveImage } from 'src/hooks/useResponsiveImage';
import { HeroOffsetImage } from 'src/components/HeroOffsetImage';
import { JoinCta } from 'src/components/JoinCta';
import { AlreadyMemberButton } from 'src/components/already-member-button';

const MagazineHero: React.FC = () => {
  const assets = useMagazineAssets();
  const { hero } = assets;

  const imageUrl = useResponsiveImage({
    mobile: hero.images[0].url,
    tablet: hero.images[1].url,
    desktop: hero.images[2].url,
  });

  return (
    <section data-cy="magazine-hero">
      <HeroOffsetImage
        title={hero.title}
        subtitle={hero.subtitle}
        imageAlt={hero.images[0].alt}
        imageUrl={imageUrl}
        copyChildren={
          <>
            <JoinCta
              className="button__gradient"
              testid="magazine-hero-link"
              disclaimerClassName="disclaimer-light-link"
            />
            <AlreadyMemberButton className="mt-3" theme="light" />
          </>
        }
        hasLargeTitle
      />
    </section>
  );
};

export { MagazineHero };
