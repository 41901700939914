import React from 'react';

import styles from './MagazineQuotes.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import { QuotesSlider } from 'src/components/QuotesSlider/QuotesSlider';

const MagazineQuotes: React.FC = () => {
  const assets = useMagazineAssets();
  const { quotes } = assets;

  return (
    <div className={`${styles.magazineQuotes} color-background-blue-gradient`} data-cy="magazine-quotes">
      <QuotesSlider quotes={quotes} hasLargeQuote />
    </div>
  );
};

export { MagazineQuotes };
