import React from 'react';

import styles from './MagazineStats.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import { useMediaMobile } from 'src/hooks/useMediaMobile';
import { CopyImageColumns } from 'src/components/CopyMediaColumns';
import FadeIn from 'src/components/FadeIn';

const MagazineStats: React.FC = () => {
  const assets = useMagazineAssets();
  const { stats } = assets;
  const isMobile = useMediaMobile();

  const statsList = (
    <ul className={styles.magazineStats__statsList}>
      {stats.list.map((stat) => {
        const [number, ...rest] = stat.split(' ');
        const description = rest.join(' ');
        return (
          <li key={`magazine-stat-${stat}`} data-cy={`magazine-stat-${stat}`} className={styles.magazineStats__stat}>
            <strong className={styles.magazineStats__statNumber}>{number}</strong>
            <br />
            <span className={styles.magazineStats__statDescription} dangerouslySetInnerHTML={{ __html: description }} />
          </li>
        );
      })}
    </ul>
  );

  const image = (
    <FadeIn duration={0.3}>
      <img
        className={styles.magazineStats__image}
        alt={stats.image.alt}
        src={stats.image.url}
        data-cy="copy-image-columns-image"
      />
    </FadeIn>
  );

  return (
    <section className={styles.magazineStats} data-cy="magazine-stats">
      <div className="container">
        <CopyImageColumns
          copyChildren={!isMobile && statsList}
          copyContainerClassName={styles.magazineStats__copyContainer}
          title={stats.title}
          paragraph={stats.paragraph}
          imageAlt={stats.image.alt}
          imageUrl={stats.image.url}
          imageChildren={!isMobile && image}
          isXLargeTitle
          isLargerParagraph
          reverseOrder
        />
        {isMobile && statsList}
      </div>
    </section>
  );
};

export { MagazineStats };
