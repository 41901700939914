import React from 'react';

import styles from './MagazineContributors.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import FadeIn from 'src/components/FadeIn';

const MagazineContributors: React.FC = () => {
  const assets = useMagazineAssets();
  const { contributors } = assets;

  return (
    <section className={styles.magazineContributors} data-cy="magazine-contributors">
      <div className="container">
        <FadeIn duration={0.4} up>
          <h2
            className={`${styles.magazineContributors__heading} text-display_2 no-padding`}
            data-cy="magazine-contributors-title"
          >
            {contributors.title}
          </h2>
        </FadeIn>
        <div className={styles.magazineContributors__container}>
          {contributors.list.map((contributor) => {
            const splitTitle = contributor.title.split(' ');
            return (
              <div
                key={`magazine-contributor-${contributor.title}`}
                data-cy={`magazine-contributor-${contributor.title}`}
                className={styles.magazineContributors__item}
              >
                <div className={styles.magazineContributors__itemContainer}>
                  <FadeIn duration={0.4}>
                    <img
                      className={styles.magazineContributors__image}
                      src={contributor.image.url}
                      alt={contributor.image.alt}
                      data-cy="magazine-contributor-image"
                    />
                  </FadeIn>
                  <FadeIn duration={0.4} up>
                    <p
                      className={`${styles.magazineContributors__overline} font-family-mono`}
                      data-cy="magazine-contributor-overline"
                    >
                      {contributor.overline}
                    </p>
                    <p className="text-display_2 no-padding" data-cy="magazine-contributor-title">
                      {`${splitTitle[0]} `}
                      <br className="display-mq-large-none" />
                      {splitTitle[1]}
                    </p>
                    <p className={styles.magazineContributors__subtitle} data-cy="magazine-contributor-subtitle">
                      {contributor.subtitle}
                    </p>
                  </FadeIn>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { MagazineContributors };
