import React from 'react';

import styles from './MagazinePublication.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import { useMediaMobile } from 'src/hooks/useMediaMobile';
import { CopyImageColumns, Variant } from 'src/components/CopyMediaColumns';
import { CheckList, Checkmark } from 'src/components/CheckList';

const MagazinePublication: React.FC = () => {
  const assets = useMagazineAssets();
  const { publication } = assets;
  const isMobile = useMediaMobile();

  const imageUrl = isMobile ? '' : publication.image.url;

  return (
    <section className={`${styles.magazinePublication} color-background-blue-gradient`} data-cy="magazine-publication">
      {isMobile && (
        <img
          className={styles.magazinePublication__mobileImage}
          src={publication.image.url}
          alt={publication.image.alt}
          data-cy="magazine-publication-image"
        />
      )}
      <div className={`${styles.magazinePublication__container} container`}>
        <CopyImageColumns
          title={publication.title}
          paragraph={publication.subtitle}
          imageAlt={publication.image.alt}
          imageUrl={imageUrl}
          copyChildren={<CheckList className="color-white" items={publication.list} checkmark={Checkmark.Blue} />}
          variant={Variant.Light}
        />
      </div>
    </section>
  );
};

export { MagazinePublication };
