import React from 'react';

import styles from './MagazinePerks.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import { IconBenefits } from 'src/components/IconBenefits';

const MagazinePerks: React.FC = () => {
  const assets = useMagazineAssets();
  const { perks, perksHeading } = assets;

  return (
    <div className={styles.magazinePerks} data-cy="magazine-perks">
      <div className="container">
        <IconBenefits title={perksHeading} benefits={perks} />
      </div>
    </div>
  );
};

export { MagazinePerks };
