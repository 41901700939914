import React from 'react';
import { useMedia } from 'react-use';

import styles from './MagazineContent.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import { Copy, Variant } from 'src/components/Copy';
import { ImageGrid } from 'src/components/ImageGrid';
import FadeIn from 'src/components/FadeIn';

const MagazineContent: React.FC = () => {
  const assets = useMagazineAssets();
  const { content } = assets;
  const isMobile = useMedia('(max-width: 480px)');

  const images = [
    {
      alt: content.images[0].alt,
      url: isMobile ? content.images[0].url : content.images[1].url,
    },
    {
      alt: content.images[2].alt,
      url: isMobile ? content.images[2].url : content.images[3].url,
    },
    {
      alt: content.images[4].alt,
      url: isMobile ? content.images[4].url : content.images[5].url,
    },
  ];

  return (
    <section className={styles.magazineContent} data-cy="magazine-content">
      <FadeIn duration={0.4} up>
        <div className="container">
          <div className={styles.magazineContent__copyContainer}>
            <Copy title={content.title} subtitle={content.subtitle} variant={Variant.Light} />
          </div>
        </div>
      </FadeIn>
      <ImageGrid images={images} />
    </section>
  );
};

export { MagazineContent };
