import React from 'react';
import cx from 'classnames';

import styles from './ImageGrid.module.scss';

import FadeIn from 'src/components/FadeIn';

type Image = {
  alt: string;
  url: string;
};

export type Props = {
  className?: string;
  images: Image[];
};

const ImageGrid: React.FC<Props> = ({ className = '', images }) => {
  return (
    <div className={cx(styles.imageGrid, className)} data-cy="image-grid">
      {images.map((image, index) => (
        <div key={`image-grid-${image.alt}`} className={styles.imageGrid__imageContainer}>
          <FadeIn duration={0.4}>
            <img className="full-width-image" src={image.url} alt={image.alt} data-cy={`image-grid-image-${index}`} />
          </FadeIn>
        </div>
      ))}
    </div>
  );
};

export { ImageGrid };
