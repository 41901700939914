import { Benefit } from 'src/components/IconBenefits';

type Image = {
  alt: string;
  url: string;
};

export type Hero = {
  title: string;
  subtitle: string;
  images: Image[];
};

type Contributor = {
  overline: string;
  title: string;
  subtitle: string;
  image: Image;
};

export type Contributors = {
  title: string;
  list: Contributor[];
};

export type Publication = {
  title: string;
  subtitle: string;
  list: string[];
  image: Image;
};

export type PagesBackground = {
  images: Image[];
};

export type Stats = {
  title: string;
  paragraph: string;
  list: string[];
  image: Image;
};

export type Content = {
  title: string;
  subtitle: string;
  images: Image[];
};

type Quote = {
  quote: string;
  member: string;
};

export type Quotes = Quote[];

export type Perks = Benefit[];

export type PerksHeading = string;

export type Cta = Hero;

export enum Resource {
  Cta = 'magazine.cta',
  Content = 'magazine.content',
  ContributorsList = 'magazine.contributors.list',
  ContributorsTitle = 'magazine.contributors.title',
  Hero = 'magazine.hero',
  PagesShots = 'magazine.pages.shots',
  PagesLandscape = 'magazine.pages.landscape',
  Perks = 'magazine.perks',
  PerksHeading = 'magazine.perks.heading',
  Publication = 'magazine.publication',
  Quotes = 'magazine.quotes',
  Stats = 'magazine.stats',
}

export type Assets = {
  cta: Cta;
  content: Content;
  contributors: Contributors;
  hero: Hero;
  pagesShots: PagesBackground;
  pagesLandscape: PagesBackground;
  perks: Perks;
  perksHeading: PerksHeading;
  publication: Publication;
  quotes: Quotes;
  stats: Stats;
};
