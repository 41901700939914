import React from 'react';

import styles from './MagazineCta.module.scss';

import { useMagazineAssets } from '../useMagazineAssets';
import { useMediaMobile } from 'src/hooks/useMediaMobile';
import { CopyImageColumns, Variant } from 'src/components/CopyMediaColumns';
import { JoinCta } from 'src/components/JoinCta';

const MagazineCta: React.FC = () => {
  const assets = useMagazineAssets();
  const { cta } = assets;
  const isMobile = useMediaMobile();

  const imageUrl = isMobile ? cta.images[0].url : cta.images[1].url;

  return (
    <section className={`${styles.magazineCta} color-background-blue-gradient`} data-cy="magazine-cta">
      <div className="container">
        <CopyImageColumns
          className={styles.magazineCta__copyImage}
          title={cta.title}
          paragraph={cta.subtitle}
          imageAlt={cta.images[0].alt}
          imageUrl={imageUrl}
          isXLargeTitle
          variant={Variant.Light}
          reverseOrder
          copyChildren={
            <JoinCta
              className="button__gradient"
              disclaimerClassName={`${styles.magazineCta__disclaimer} color-white disclaimer-light-link`}
              testid="magazine-hero-link"
            />
          }
        />
      </div>
    </section>
  );
};

export { MagazineCta };
